import React, { useEffect, useState } from "react"
import {
  GridBlackTitle,
  GridBox,
  GridContainer,
  GridSubtitle,
  GridSpacer,
  Grid5Faq,
  Standalone,
  Text,
} from "@thedgbrt/logology-ui-components"
import CurrencySelector, {
  currencies,
} from "../Proposal/ProposalBuy/CurrencySelector"
import PricingPlans from "./PlansNew"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"

const Pricing = () => {
  const [price, setPrice] = useState(currencies["us"])

  useEffect(() => {
    trackSegmentEvent("track", "View pricing page")
    trackAmplitude("View pricing page")
  }, [])

  return (
    <>
      <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [2, 10]]} white>
        Look professional from day 1
      </GridBlackTitle>
      <GridSubtitle gridType={5} colSpans={[[3, 11], [3, 11], [3, 11]]}>
        Get a credible brand whether you just have an idea, a M.V.P or a growing
        product.
      </GridSubtitle>

      <GridSpacer desktop={54} mobile={28} tablet={28} type={5} />

      <GridContainer type={5}>
        <GridBox colSpans={[[3, 8], [3, 8], [9, 11]]} type={5}>
          <CurrencySelector handleChange={setPrice} removeOffset />
        </GridBox>
      </GridContainer>

      <PricingPlans
        priceStart={price.starter.priceNew}
        priceGrow={price.growth.priceNew}
        priceEstablish={price.custom.priceNew}
      />

      <GridSpacer desktop={156} mobile={105} tablet={105} type={5} />

      <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [2, 9]]}>
        Tailored for makers and founders
      </GridBlackTitle>

      <Grid5Faq
        items={[
          {
            title: "Only pay for the difference when you upgrade",
            content: (
              <Text italic>
                If you pick a grow package today, you can upgrade to an
                establish package later by paying the difference only. This
                applies to all packages.
              </Text>
            ),
          },
          // {
          //   title: "Get up to 50% off if you buy multiple logos in a year",
          //   content: (
          //     <Text italic>
          //       If you launch a lot of projects, we're happy to give you 25% off
          //       your second purchase, and 50% off your third one. Counted within
          //       1-year of your first purchase. Applicable to "start" and "grow"
          //       packages only.
          //     </Text>
          //   ),
          // },
          {
            title: "Unlimited design changes and support",
            content: (
              <Text italic>
                After your purchase, you can change the brand name, color or
                font of your logo as many times as you need, and re-download the
                files. We’re also available to help you at{" "}
                <a href="mailto:support@logology.co">support@logology.co</a>.
              </Text>
            ),
          },
        ]}
      />

      <GridSpacer desktop={90} mobile={65} tablet={65} type={5} />

      <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [2, 9]]}>
        Questions &amp; answers
      </GridBlackTitle>

      <GridBox colSpans={[[3, 8], [3, 8], [3, 9]]} withBgContainer type={5}>
        <Standalone matchGridFaq>
          <Text italic>
            <p>
              <b>Is the logo removed from the catalog after I buy it?</b>
              <br />
              The idea of Logology is to offer a designer logo at an affordable
              price when you're starting out ($69 and $119 packages). In these
              cases, the logo won't be removed from the catalog. With the more
              expensive $799 option however, we make a unique variation of the
              logo, so you're the only one to have it.
            </p>
            <p>
              Our idea is that when you're just getting started, it's more
              important to have something that looks professional but stays
              within your budget.
            </p>
          </Text>
        </Standalone>
      </GridBox>

      <Grid5Faq
        items={[
          {
            title: "Can I trademark my logo?",
            content: (
              <Text italic>
                With the "establish" logo package, you get a unique design with
                full exclusivity and trademarking rights. You can then apply for
                a trademark within your country's jurisdiction.
              </Text>
            ),
          },
          {
            title: "What if I made a mistake in my order?",
            content: (
              <Text italic>
                If you made a mistake in your brand name, color, or font choice,
                we're happy to fix it at no additional cost. Just email us at{" "}
                <a href="mailto:support@logology.co">support@logology.co</a>.
              </Text>
            ),
          },
          {
            title: "Can I resell or distribute the logo elsewhere?",
            content: (
              <Text italic>
                If you are a designer or agency that needs to resell to your
                customers, please contact us at{" "}
                <a href="mailto:support@logology.co">support@logology.co</a> for
                a custom contract. We do not currently allow reselling or
                distributing on other platforms.
              </Text>
            ),
          },
        ]}
      />
    </>
  )
}

export default Pricing
