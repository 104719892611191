import React from "react"
import SEO from "../../components/seo"
import Pricing from "../../components/Pricing"

const PricingPage = () => (
  <>
    <SEO title="Pricing" />
    <Pricing />
  </>
)

export default PricingPage
