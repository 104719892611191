import React from "react"
import {
  CheckoutPriceWrapper2,
  FormSelect,
} from "@thedgbrt/logology-ui-components"

export const currencies = {
  us: {
    name: "USD $",
    tax: "(tax: $0)",
    value: "us",
    index: 0,
    starter: {
      priceOld: "$69.00",
      priceNew: "$69.00",
      currency: "USD",
      value: "69",
      skuDev: "price_1L09NkCbpOuNJiYkC81W9Vom",
      skuProduction: "price_1L09MFCbpOuNJiYkWbqZ0LT4",
    },
    growth: {
      priceOld: "$119.00",
      priceNew: "$119.00",
      currency: "USD",
      value: "119",
      skuDev: "price_1L09OaCbpOuNJiYkiqKw7wxj",
      skuProduction: "price_1L09PcCbpOuNJiYkyWxFC1wb",
    },
    custom: {
      priceOld: "$799.00",
      priceNew: "$799.00",
      currency: "USD",
      value: "799",
    },
  },
  // ca: {
  //   name: "CAD $",
  //   starter: {
  //     priceOld: "CAD$89.00",
  //     priceNew: "CAD$89.00",
  //     currency: "CAD",
  //     value: "89",
  //     skuDev: "price_1L09NyCbpOuNJiYk6LdoZG02",
  //     skuProduction: "price_1L09MgCbpOuNJiYk3dYvRxmp",
  //   },
  //   growth: {
  //     priceOld: "CAD$149.00",
  //     priceNew: "CAD$149.00",
  //     currency: "CAD",
  //     value: "149",
  //     skuDev: "price_1L09P1CbpOuNJiYkiv4OSvfE",
  //     skuProduction: "price_1L09Q8CbpOuNJiYkWx8w9v2f",
  //   },
  //   custom: {
  //     priceOld: "CAD$769.00",
  //     priceNew: "CAD$769.00",
  //     currency: "CAD",
  //     value: "769",
  //   },
  //   tax: "(tax: CAD$0)",
  //   value: "ca",
  //   index: 1,
  // },
  // gb: {
  //   name: "GBP £",
  //   starter: {
  //     priceOld: "56.00£",
  //     priceNew: "56.00£",
  //     currency: "GBP",
  //     value: "56",
  //     skuDev: "price_1L09OFCbpOuNJiYkfNLrwbVw",
  //     skuProduction: "price_1L09N0CbpOuNJiYkomGTbTfI",
  //   },
  //   growth: {
  //     priceOld: "97.00£",
  //     priceNew: "97.00£",
  //     currency: "GBP",
  //     value: "97",
  //     skuDev: "price_1L09PJCbpOuNJiYkQJD6Lxur",
  //     skuProduction: "price_1L09QKCbpOuNJiYkQdoj3Xot",
  //   },
  //   custom: {
  //     priceOld: "486.00£",
  //     priceNew: "486.00£",
  //     currency: "GBP",
  //     value: "486",
  //   },
  //   tax: "tax: (0£)",
  //   value: "gb",
  //   index: 3,
  // },
  eu: {
    name: "EUR €",
    starter: {
      priceOld: "69,00€",
      priceNew: "69,00€",
      currency: "EUR",
      value: "69",
      skuDev: "price_1L09NYCbpOuNJiYk9BoJo2cj",
      skuProduction: "price_1L09KtCbpOuNJiYkRaIAtSNP",
    },
    growth: {
      priceOld: "119,00€",
      priceNew: "119,00€",
      currency: "EUR",
      value: "119",
      skuDev: "price_1L09OjCbpOuNJiYkSckU7ECl",
      skuProduction: "price_1L09PmCbpOuNJiYkQhffyD31",
    },
    custom: {
      priceOld: "799,00€",
      priceNew: "799,00€",
      currency: "EUR",
      value: "799",
    },
    tax: "tax: (0€)",
    value: "eu",
    index: 4,
  },
}

const CurrencySelector = ({ handleChange, removeOffset }) => (
  <CheckoutPriceWrapper2
    style={removeOffset ? { height: "100%", marginTop: 0 } : { height: "100%" }}
  >
    <p>prices in</p>

    <FormSelect
      defaultValue="us"
      elements={Object.keys(currencies)
        .map(k => currencies[k])
        .sort((a, b) => (a.index < b.index ? -1 : a.index > b.index))}
      full
      inline
      label=""
      onChange={value => handleChange(currencies[value])}
    />
  </CheckoutPriceWrapper2>
)

export default CurrencySelector
